<template>
  <v-form autocomplete="off">
    <v-divider
      class="mt-3"
      style="border-color: grey;"
    />
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-checkbox
          v-model="value.enabled"
          class="font-weight-bold"
          :label="`${value.customName || name}: ${value.enabled ? 'Habilitado' : 'Desabilitado'}`"
          color="success"
        />
      </v-col>
      <v-col
        v-if="value.enabled"
        cols="12"
        sm="6"
        md="4"
      >
        <v-menu
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          readonly
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{}">
            <v-text-field
              v-model="value.createdAt"
              v-mask="'####-##-##'"
              placeholder="AAAA-MM-DD"
              label="Data de criação"
              prepend-icon="mdi-calendar"
              readonly
            />
          </template>
          <v-date-picker
            v-model="value.createdAt"
            class="pb-0 mb-0 mt-0 pt-5"
            no-title
            scrollable
            @input="dateMenu = false"
          />
        </v-menu>
      </v-col>
    </v-row>
    <template
      v-if="value.enabled"
    >
      <v-row
        align="center"
        justify="start"
      >
        <v-col
          v-if="value.limitUser !== undefined"
          cols="4"
        >
          <v-text-field
            v-model="value.limitUser"
            label="Limite de Usuários:"
          />
        </v-col>
        <v-col
          v-if="value.theme !== undefined"
          cols="4"
        >
          <v-text-field
            v-model="value.theme"
            label="Tema:"
          />
        </v-col>
        <v-col
          v-if="value.logo !== undefined"
          cols="4"
        >
          <v-text-field
            v-model="value.logo"
            label="Logo:"
          />
        </v-col>
        <v-col
          v-if="value.colorHTML !== undefined"
          cols="4"
        >
          <v-text-field
            v-model="value.colorHTML"
            label="Cor HTML"
            hide-details
            class="ma-0 pa-0"
            solo
            @click="colorpickerMenu = true"
          >
            <template v-slot:append>
              <v-menu
                v-model="colorpickerMenu"
                top
                nudge-bottom="105"
                nudge-left="16"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <div
                    :style="swatchStyle"
                    v-on="on"
                  />
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker
                      v-model="value.colorHTML"
                      flat
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row
        v-if="value.printable !== undefined"
        no-gutters
      >
        <v-col>
          <v-row
            no-gutters
            class="ms-3"
          >
            <h5>Impressos</h5>
          </v-row>
          <v-row
            no-gutters
          >
            <v-col
              v-if="value.printable.logo !== undefined"
              cols="12"
              md="6"
              class="px-2"
            >
              <v-text-field
                v-model="value.printable.logo"
                label="Logo:"
                hint="URL da imagem"
                persistent-hint
              />
            </v-col>
            <v-col
              v-if="value.printable.theme !== undefined"
              cols="12"
              class="px-2"
              md="6"
            >
              <v-text-field
                v-model="value.printable.theme"
                label="Temas:"
                hint="Separados por espaços"
                persistent-hint
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row
        v-if="value.settings !== undefined"
        no-gutters
      >
        <v-col>
          <v-row
            no-gutters
            class="ms-3 mt-4"
          >
            <h5>Configurações</h5>
          </v-row>
          <v-row
            no-gutters
          >
            <v-col
              v-for="(setting, index) in Object.keys(value.settings)"
              :key="`SETTING${index}`"
              class="px-2"
              cols="12"
            >
              <v-row
                class="my-1"
              >
                <v-text-field
                  :value="setting"
                  label="Chave"
                  class="me-3"
                />
                <v-text-field
                  v-model="value.settings[setting]"
                  label="valor"
                />
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </template>
    <div class="py-3" />
    <v-divider
      class="mt-3"
      style="border-color: grey;"
    />
  </v-form>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import moment from 'moment'

  export default {
    name: 'ServiceBox',
    directives: {
      mask,
    },
    model: {
      prop: 'value',
      event: 'input',
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      value: {
        type: Object,
        required: false,
        default: () => ({
          enabled: false,
          createdAt: null,
        }),
      },
    },
    data: () => {
      return {
        dateMenu: false,
        colorpickerMenu: false,
      }
    },
    computed: {
      swatchStyle: function () {
        return {
          backgroundColor: this.value.colorHTML,
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: this.colorpickerMenu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out',
        }
      },
    },
    watch: {
      'value.enabled': {
        immediate: true,
        handler (val) {
          if (val) {
            this.value.createdAt = moment().toISOString()
          }
        },
      },
    },
  }
</script>
